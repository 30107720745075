import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {FixedHeightColumn} from '../../parts/fixed-height-column'
import {TableRow} from '../../../../table-row'
import {LimitReachedTooltip} from '../../parts/limit-reached-tooltip'
import s from './pricing-option.scss'
import {PricingOptionName} from './pricing-option-name'
import {PricingOptionPrice} from './pricing-option-price'
import {PricingOptionQuantity} from './pricing-option-quantity'
import {PricingOptionTotal} from './pricing-option-total'
import {PricingOptionProps} from './interfaces'

export const PricingOption = ({pricingOption, ticket}: PricingOptionProps) => {
  return (
    <TableRow
      config={{
        itemKey: `${ticket.id}-pricing-option-${pricingOption.id}`,
        responsiveHideColumns: [1],
        className: s.row,
        dataHook: DH.TICKET, // TODO
        columns: [
          {
            className: s.overflow,
            content: (
              <FixedHeightColumn className={s.nameContainer}>
                <PricingOptionName ticket={ticket} pricingOption={pricingOption} />
              </FixedHeightColumn>
            ),
          },
          {
            content: (
              <FixedHeightColumn>
                <PricingOptionPrice ticket={ticket} pricingOption={pricingOption} />
              </FixedHeightColumn>
            ),
          },
          {
            className: s.overflow,
            content: (
              <FixedHeightColumn>
                <LimitReachedTooltip ticket={ticket} minWidth={140} pricingOptionId={pricingOption.id}>
                  <PricingOptionQuantity ticket={ticket} pricingOption={pricingOption} />
                </LimitReachedTooltip>
              </FixedHeightColumn>
            ),
          },
          {
            content: (
              <FixedHeightColumn className={s.total}>
                <PricingOptionTotal ticket={ticket} pricingOption={pricingOption} />
              </FixedHeightColumn>
            ),
          },
        ],
      }}
    />
  )
}
