import React from 'react'
import {Ticket} from '../ticket'
import {checkDonationError} from '../../../../services/donations'
import s from './columns.scss'
import {ColumnsProps} from './index'

export const Columns = ({
  tickets,
  selectedTickets,
  selectTicket,
  setDonationError,
  reserveTicketsPending,
}: ColumnsProps) => {
  const onQuantityPickerChange = ({ticketId, count}: {ticketId: string; count: number}) => {
    if (reserveTicketsPending) {
      return
    }

    const currentCount = selectedTickets[ticketId]?.quantity ?? 0
    if (currentCount < count) {
      const ticket = tickets.find(({id}) => id === ticketId)
      const error = checkDonationError(ticket, selectedTickets[ticketId]?.donation)
      if (error) {
        setDonationError({ticketId, error})
        return
      }
    }
    selectTicket({ticketId, count})
  }

  return (
    <ul className={s.list} role="rowgroup">
      {tickets.map(ticket => (
        <li key={ticket.id}>
          <Ticket ticket={ticket} onChange={onQuantityPickerChange} />
        </li>
      ))}
    </ul>
  )
}
