import {getSelectedTickets} from '../../../../selectors/selected-tickets'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isRequestPending} from '../../../../selectors/pending-requests'
import {RESERVE_TICKETS} from '../../../../actions/reservation'
import {Columns as Presentation} from './columns'
import {ColumnsOwnProps, ColumnsRuntimeProps} from './interfaces'

export const mapRuntime = ({
  state,
  actions: {selectTicket, setDonationError},
}: DetailsPageAppProps): ColumnsRuntimeProps => ({
  reserveTicketsPending: isRequestPending(state, RESERVE_TICKETS.REQUEST),
  tickets: state.tickets,
  selectedTickets: getSelectedTickets(state),
  selectTicket,
  setDonationError,
})

export const Columns = connect<ColumnsOwnProps, ColumnsRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
