import React from 'react'
import {Tooltip} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import s from './limit-reached-tooltip.scss'
import {LimitReachedTooltipProps} from './interfaces'

export const LimitReachedTooltip: React.FC<LimitReachedTooltipProps> = ({
  children,
  maxTicketsPerReservation,
  show,
  ...props
}) => {
  const {t} = useTranslation()
  return (
    <Tooltip
      className={s.tooltip}
      disabled={!show}
      shown={show}
      placement="right"
      {...props}
      content={
        maxTicketsPerReservation === 1
          ? t('ticketLimitPerReservationReached_singular')
          : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
      }
    >
      {children}
    </Tooltip>
  )
}
