import React from 'react'
import {QuantityPicker} from '../../../parts/quantity-picker'
import s from './pricing-option-quantity.scss'
import {PricingOptionsQuantityProps} from './interfaces'

export const PricingOptionQuantity = ({
  selectedQuantity,
  max,
  disabled,
  selectTicket,
  ticket,
  pricingOption,
}: PricingOptionsQuantityProps) => {
  return (
    <div className={s.quantity}>
      <QuantityPicker
        quantity={selectedQuantity}
        min={0}
        max={max}
        disabled={disabled}
        onChange={value => selectTicket({ticketId: ticket.id, count: value, pricingOptionId: pricingOption.id})}
      />
    </div>
  )
}
